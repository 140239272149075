import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import WalletConnect from '@walletconnect/client';

import tokenAbi from './abi/tokenAbi';
import erc20Abi from './abi/erc20';

const nftAddress = process.env.REACT_APP_NFT_ADDRESS;

const BASE_COINGECKO_URL = 'https://api.coingecko.com/api/v3/coins';
const OUR_TOKEN = 'eqifi';

export const isAllowanceSmallerThanAmount = async (
  erc20Address,
  ownerAddress,
  spenderAddress,
  amount,
) => {
  const web3 = new Web3(process.env.REACT_APP_ETHEREUM_NETWORK_URL);

  const erc20Contract = new web3.eth.Contract(erc20Abi.abi, erc20Address);

  const allowance = new BigNumber(
    await erc20Contract.methods.allowance(ownerAddress, spenderAddress).call(),
  ).toString(16);

  return new BigNumber(amount, 10).isGreaterThan(allowance, 16);
};

export const weiToDividedNumber = (amount, decimals) =>
  new BigNumber(amount).div(new BigNumber(10).pow(decimals)).toNumber();

export const humanReadableToWeiString = (amount, decimals) =>
  new BigNumber(amount).multipliedBy(new BigNumber(10).pow(decimals)).toNumber();

export const getUsdPrice = async (amount) => {
  const url = `${BASE_COINGECKO_URL}/${OUR_TOKEN}`;
  try {
    const response = await fetch(url);
    if (!response) return amount * 0.08;

    const resData = await response.json();
    const usdPrice = resData?.market_data?.current_price?.usd;

    return amount * usdPrice;
  } catch (err) {
    console.log('err', err);
  }
};

const getDecimals = async (token) => {
  try {
    const web3 = new Web3(process.env.REACT_APP_ETHEREUM_NETWORK_URL);
    const erc20Contract = new web3.eth.Contract(erc20Abi.abi, token);

    const decimals = await erc20Contract.methods.decimals().call();
    return decimals;
  } catch (error) {
    console.log('error', error);
  }
};

export const getBalance = async () => {
  try {
    const web3 = new Web3(process.env.REACT_APP_ETHEREUM_NETWORK_URL);

    const tokenContract = new web3.eth.Contract(tokenAbi?.abi, nftAddress);
    const paymentToken = await tokenContract.methods.paymentToken().call();

    const wallet = JSON.parse(localStorage.getItem('walletconnect'));
    if (!!wallet) {
      const walletId = wallet?.accounts[0];

      const erc20Contract = new web3.eth.Contract(erc20Abi.abi, paymentToken);

      const balance = await erc20Contract.methods.balanceOf(walletId).call();

      const decimals = await getDecimals(paymentToken);
      return weiToDividedNumber(balance, decimals);
    }
  } catch (error) {
    console.log('error', error);
  }
};

const getNftInfo = async (tokenUri) => {
  const response = await fetch(tokenUri);
  const resData = await response.json();

  return resData;
};

export const getNfts = async () => {
  try {
    const web3 = new Web3(process.env.REACT_APP_ETHEREUM_NETWORK_URL);

    const tokenContract = new web3.eth.Contract(tokenAbi?.abi, nftAddress);

    const paymentToken = await tokenContract.methods.paymentToken().call();

    const nftDecimals = await getDecimals(paymentToken);

    const nfts = await tokenContract.methods.getAllNFTs().call();
    let nftLists = [];

    const usdPriceEqx = await getUsdPrice(1);

    for (const nft of nfts) {
      const { id, price, sold, tokenUri } = nft;
      const nftData = await getNftInfo(tokenUri);

      nftLists = [
        ...nftLists,
        {
          id,
          price: weiToDividedNumber(price, nftDecimals),
          sold,
          tokenUri,
          usdPrice: weiToDividedNumber(usdPriceEqx * price, nftDecimals) || 0,
          nftData,
        },
      ];
    }

    return nftLists;
  } catch (error) {
    console.log('error', error);
  }
};

export const buyNft = async (nft, connector, setLoading) => {
  try {
    setLoading(true);
    const web3 = new Web3(process.env.REACT_APP_ETHEREUM_NETWORK_URL);
    const tokenContract = new web3.eth.Contract(tokenAbi?.abi, nftAddress);

    const paymentToken = await tokenContract.methods.paymentToken().call();
    const nftDecimals = await getDecimals(paymentToken);

    const wallet = JSON.parse(localStorage.getItem('walletconnect'));

    const weiAmount = humanReadableToWeiString(nft?.price, nftDecimals);

    const walletId = wallet?.accounts[0];

    await genericApproval(paymentToken, walletId, nftAddress, weiAmount, connector);

    const method = tokenContract.methods.mint(nft?.id);

    const tx = await genericSendTransaction(walletId, nftAddress, connector, method, '0x00', true);

    console.warn('tx', tx);

    if (tx?.transactionHash) {
      return { hash: tx?.transactionHash, id: nft?.id, status: tx?.status };
    }
  } catch (error) {
    console.log('error', error);
  } finally {
    setLoading(false);
  }
};

export const subscribeToEvents = (connector, setConnector, setWalletFlag, setNetworkId) => {
  if (!connector) {
    return;
  }
  // Subscribe to connection events
  connector?.on('connect', async (error, payload) => {
    if (error) {
      throw error;
    }
    // Get provided accounts and chainId
    const { accounts, chainId } = payload?.params[0];
    setNetworkId(chainId);
    const walletAccount = accounts[0]?.toLowerCase();
    setWalletFlag(true);
    setConnector(connector);
  });

  connector?.on('session_update', async (error, payload) => {
    if (error) {
      throw error;
    }

    const { accounts, chainId } = payload?.params[0];
    setNetworkId(chainId);
    const walletAccount = accounts[0]?.toLowerCase();
    setConnector(connector);
  });

  connector?.on('disconnect', async (error) => {
    if (error) {
      throw error;
    }
    setConnector(null);
    setWalletFlag(false);
    setNetworkId(null);
    localStorage.removeItem('walletconnect');
  });
};

export const connectToWalletConnectHandler = (connector) => {
  connector?.connected ? connector?.killSession() : connector?.createSession();
};

export const genericApproval = async (
  erc20Address,
  ownerAddress,
  spenderAddress,
  amount,
  connector,
) => {
  const web3 = new Web3(process.env.REACT_APP_ETHEREUM_NETWORK_URL);

  const approveNeeded = await isAllowanceSmallerThanAmount(
    erc20Address,
    ownerAddress,
    spenderAddress,
    amount,
    web3,
  );

  if (approveNeeded) {
    const erc20Contract = new web3.eth.Contract(erc20Abi.abi, erc20Address);
    const approveMethod = erc20Contract.methods.approve(
      spenderAddress,
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
    );

    // wallet connect approval
    const approveData = approveMethod.encodeABI();
    const gasResult = '50000';
    const nonce = await web3.eth.getTransactionCount(ownerAddress);
    const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const getTransactionReceipt = async (txHash) => {
      const transactionReceipt = await web3.eth.getTransactionReceipt(txHash);
      if (transactionReceipt) return transactionReceipt;
      await timeout(5000);
      return await getTransactionReceipt(txHash);
    };

    // raw approval transaction
    const tx = {
      from: ownerAddress,
      to: erc20Address,
      data: approveData,
      gas: gasResult,
      nonce: nonce,
      value: '0x00',
    };

    const wallet = JSON.parse(localStorage.getItem('walletconnect'));

    const newConnector = new WalletConnect({
      uri: `wc:${wallet?.handshakeTopic}@${wallet?.version}?bridge=${wallet?.bridge}&key=${wallet?.key}`,
    });
    const response = await newConnector?.sendTransaction(tx);

    await getTransactionReceipt(response);
    return response;
  }
  return true;
};

export const genericSendTransaction = async (
  fromAddress,
  toAddress,
  connector,
  method,
  value,
  needRecipe = false,
) => {
  const web3 = new Web3(process.env.REACT_APP_ETHEREUM_NETWORK_URL);

  let response;

  // walletConnect send transaction
  const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getTransactionReceipt = async (txHash) => {
    const transactionReceipt = await web3.eth.getTransactionReceipt(txHash);
    if (transactionReceipt) return transactionReceipt;
    await timeout(5000);
    return await getTransactionReceipt(txHash);
  };

  let dataEncoded;
  if (method) {
    dataEncoded = method.encodeABI();
  }
  const nonce = await web3.eth.getTransactionCount(fromAddress);

  const transaction = {
    from: fromAddress,
    to: toAddress,
    data: dataEncoded || undefined,
    gas: 200000,
    value: value || '0x00',
    nonce: nonce,
  };

  const wallet = JSON.parse(localStorage.getItem('walletconnect'));

  const newConnector = new WalletConnect({
    uri: `wc:${wallet?.handshakeTopic}@${wallet?.version}?bridge=${wallet?.bridge}&key=${wallet?.key}`,
  });

  const txHash = await newConnector?.sendTransaction(transaction);
  response = { transactionHash: txHash };

  if (needRecipe) {
    response = await getTransactionReceipt(txHash);
    response.events = response.logs.map((log) => {
      return {
        ...log,
        raw: {
          topics: log.topics,
          data: log.data,
        },
      };
    });
  }

  return response;
};
