export const numberWithCommas = (no) => !!no && no.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const extractArtistData = (data) => {
  const { artistName, artistDescription, artistInstagramLink, artistWebsiteLink, artistImage } =
    data;
  return { artistName, artistDescription, artistInstagramLink, artistWebsiteLink, artistImage };
};

export const formatNftsByArtists = (nfts) => {
  const nftByArtists = nfts?.reduce((acc, curr) => {
    const existsFlag = acc?.findIndex((el) => el?.artist?.artistName === curr?.nftData?.artistName);
    if (existsFlag < 0) {
      return [...acc, { artist: { ...extractArtistData(curr?.nftData) }, nfts: [curr] }];
    } else {
      acc[existsFlag]?.nfts?.push(curr);
      return acc;
    }
  }, []);

  return nftByArtists;
};
