import { useState, useEffect } from 'react';
import constants from 'helpers/constants';
import useWindowSize from './useWindowSize';

const useIsMobile = (breakpoint = constants.MOBILE_BREAKPOINT) => {
  const { width } = useWindowSize();
  const [isMobile, setMobile] = useState(width <= (breakpoint || constants.MOBILE_BREAKPOINT));

  useEffect(() => {
    setMobile(width <= (breakpoint || constants.MOBILE_BREAKPOINT));
  }, [width]);

  return isMobile;
};

export default useIsMobile;
