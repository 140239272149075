// blue ad
import blueAdDesktop from 'assets/ads/blue_ad_desktop.png';
import blueAdMobile from 'assets/ads/blue_ad_mobile.png';

// earnup ad
import earnupAdDesktop from 'assets/ads/earnup_ad_desktop.png';
import earnupAdMobile from 'assets/ads/earnup_ad_mobile.png';

// stake eqx
import stakeEqxDesktop from 'assets/ads/stake_eqx_desktop.png';
import stakeEqxMobile from 'assets/ads/stake_eqx_mobile.png';

// unbeatable ad
import unbeatableAdDesktop from 'assets/ads/unbeatable_ad_desktop.png';
import unbeatableAdMobile from 'assets/ads/unbeatable_ad_mobile.png';

// unlock crypto ad
import unlockCryptoDesktop from 'assets/ads/unlock_crypto_desktop.png';
import unlockCryptoMobile from 'assets/ads/unlock_crypto_mobile.png';

const blueAd = {
  fullWidth: true,
  desktopImage: blueAdDesktop,
  mobileImage: blueAdMobile,
  storeFlag: true,
  positions: {
    desktop: {
      top: '50',
      left: '75',
    },
    mobile: {
      top: '90',
      left: '35',
    },
  },
};

const unlockCrypto = {
  fullWidth: false,
  desktopImage: unlockCryptoDesktop,
  mobileImage: unlockCryptoMobile,
  storeFlag: true,
  positions: {
    desktop: {
      top: '80',
      left: '19',
    },
    mobile: {
      top: '42',
      left: '35',
    },
  },
};

const stakeEqx = {
  fullWidth: false,
  desktopImage: stakeEqxDesktop,
  mobileImage: stakeEqxMobile,
  link: {
    to: 'https://platform.eqifi.com/staking',
    text: 'Start Earning',
  },
  positions: {
    desktop: {
      top: '75',
      left: '17',
    },
    mobile: {
      top: '50',
      left: '35',
    },
  },
};

const unbeatableAd = {
  fullWidth: true,
  desktopImage: unbeatableAdDesktop,
  mobileImage: unbeatableAdMobile,
  storeFlag: true,
  positions: {
    desktop: {
      top: '50',
      left: '75',
    },
    mobile: {
      top: '33',
      left: '36',
    },
  },
};

const earnupAd = {
  fullWidth: false,
  desktopImage: earnupAdDesktop,
  mobileImage: earnupAdMobile,
  link: {
    to: 'https://platform.eqifi.com/deposits',
    text: 'Deposit Now',
  },
  positions: {
    desktop: {
      top: '82',
      left: '15',
    },
    mobile: {
      top: '53',
      left: '35',
    },
  },
};

export const adsConfig = [
  blueAd,
  unlockCrypto,
  stakeEqx,
  blueAd,
  unbeatableAd,
  earnupAd,
  blueAd,
  unlockCrypto,
  unbeatableAd,
  stakeEqx,
];