import React from 'react';

import styles from './styles.module.css';

const Changelly = () => {
  return (
    <div className={styles.containerFrame}>
      <div className="container">
        <div className="row">
          <div className={`col-12 col-sm-6 ${styles.changellyInfo}`}>
            <div className={styles.title}>EQX NFT Gallery</div>
            <div className={styles.description}>
              All pieces below are available for purchase in EQX. You can buy
            </div>
          </div>
          <div className="col-12 col-sm-6" id="changelly-id">
            <iframe
              src="https://widget.changelly.com/"
              title="widget"
              className={styles.iframeChangelly}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Changelly;
