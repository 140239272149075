import React, { useState, useEffect } from 'react';

import Hero from 'components/Hero';
import Changelly from 'components/Changelly';
import MeetTheArtists from 'components/MeetTheArtists';
import Ad from 'components/Ad';
import ArtistWithNfts from 'components/ArtistWithNfts';
import Footer from 'components/Footer';
import { getNfts, getBalance } from 'helpers/wallet/walletHelpers';
import { formatNftsByArtists } from 'helpers/common';
import useInterval from 'helpers/hooks/useInterval';

import { adsConfig } from 'components/Ad/adsData';

const NftSale = () => {
  const [nfts, setNfts] = useState([]);
  const [connector, setConnector] = useState({});
  const [nftsByArtists, setNftsByArtists] = useState([]);
  const [walletFlag, setWalletFlag] = useState(!!JSON.parse(localStorage.getItem('walletconnect')));
  const [balance, setBalance] = useState();
  const [networkId, setNetworkId] = useState(
    JSON.parse(localStorage.getItem('walletconnect'))?.chainId,
  );

  useEffect(() => {
    getNfts().then((list) => setNfts(list));
    getBalance().then((bal) => setBalance(bal));
  }, []);

  useEffect(() => {
    setNftsByArtists(formatNftsByArtists(nfts));
  }, [nfts]);

  const res = useInterval();

  useEffect(() => {
    setNfts(res);
    getBalance().then((bal) => setBalance(bal));
  }, [res]);

  useEffect(() => {
    getBalance().then((bal) => setBalance(bal));
  }, [walletFlag]);

  return (
    <div>
      <Hero
        setConnector={setConnector}
        walletFlag={walletFlag}
        setWalletFlag={setWalletFlag}
        setNetworkId={setNetworkId}
        networkId={networkId}
      />
      <Changelly />
      <MeetTheArtists artists={nftsByArtists} />
      {nftsByArtists?.map((data, index) => {
        return (
          <div key={index}>
            <Ad {...adsConfig[index]} />
            <ArtistWithNfts
              key={index}
              artist={data?.artist}
              nfts={data?.nfts}
              index={index}
              balance={balance}
              connector={connector}
              setConnector={setConnector}
              walletFlag={walletFlag}
              setWalletFlag={setWalletFlag}
              setNetworkId={setNetworkId}
              networkId={networkId}
            />
          </div>
        );
      })}
      <Footer />
    </div>
  );
};

export default NftSale;
