import { useEffect, useState } from 'react';
import { getNfts } from 'helpers/wallet/walletHelpers';

const REFETCH_TIMER = 60000;

const useInterval = () => {
  const [response, setResponse] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      getNfts().then((list) => setResponse(list));
      console.warn('This will be called every one minute');
    }, REFETCH_TIMER);

    return () => clearInterval(interval);
  }, []);

  return response;
};

export default useInterval;
