import React from 'react';

import Image from 'components/Image';
import Spinner from 'components/Spinner';

import styles from './styles.module.css';

const MeetTheArtists = ({ artists }) => {
  return (
    <div className={styles.fullContainer}>
      <div className={styles.title}>Meet the Artists</div>
      <div className={styles.artistsList}>
        {!!artists?.length ? (
          artists?.map((item, index) => {
            return (
              <div key={index} className={styles.artist}>
                <Image alt={`image-${index}`} src={item?.artist?.artistImage} />
              </div>
            );
          })
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default MeetTheArtists;
