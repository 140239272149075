import React from 'react';
import scrollIntoView from 'scroll-into-view';

// assets
import nftSaleLogo from 'assets/images/nft_sale_logo.svg';
import heroBgMobile from 'assets/images/hero_bg_mobile.png';
import heroBg from 'assets/images/hero_bg.png';

import Image from 'components/Image';
import WalletConnect from 'components/WalletConnect';
import useIsMobile from 'helpers/hooks/useIsMobile';

import styles from './styles.module.css';

const Hero = ({ setConnector, walletFlag, setWalletFlag, setNetworkId, networkId }) => {
  const isMobile = useIsMobile();

  const scrollToChangelly = () => {
    const changelly = document.getElementById('changelly-id');
    scrollIntoView(changelly, {
      time: 500,
      align: 'center',
    });
  };

  return (
    <div className={styles.heroPage}>
      <Image alt="hero" src={isMobile ? heroBgMobile : heroBg} className={styles.heroImage} />
      <div className={styles.heroContent}>
        <div className={styles.headerRow}>
          <Image alt="logo" src={nftSaleLogo} className={styles.logo} />
          <WalletConnect
            setConnector={setConnector}
            walletFlag={walletFlag}
            setWalletFlag={setWalletFlag}
            setNetworkId={setNetworkId}
            networkId={networkId}
          />
        </div>
        <div className={styles.eqiverseRow}>
          <span className={styles.eqiTitle}>EQIVERSE</span>
          <span className={styles.eqiDescription}>Miami, FL | NFT Gallery</span>
        </div>
        <div className={styles.buyCard}>
          <span>
            EQIFi’s native token, EQX, offers higher earnings, ultra-low-cost loans, staking rewards
            & more.
          </span>
          <span>See what EQX can do for you.</span>
          <div className={styles.buttonsCard}>
            <button type="button" className={styles.buyButton} onClick={scrollToChangelly}>
              Buy EQX Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
