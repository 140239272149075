import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';

const Image = ({ src, alt, className, height, width, onClick }) => (
  <LazyLoadImage
    alt={alt}
    src={src}
    height={height}
    width={width}
    className={className}
    onClick={onClick}
  />
);

Image.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};

export default Image;
