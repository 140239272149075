import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/Image';
import useIsMobile from 'helpers/hooks/useIsMobile';

import googleStore from 'assets/images/google_store.svg';
import appleStore from 'assets/images/apple_store.svg';
import comingSoonApple from 'assets/images/coming_soon_apple.svg';
import getGoogleStore from 'assets/images/get_google_store.svg';
import arrowRight from 'assets/images/arrow_right.svg';

import styles from './styles.module.css';

const Ad = (props) => {
  const { fullWidth, desktopImage, mobileImage, storeFlag, positions, link } = props;

  const isMobile = useIsMobile();

  const renderButtons = useCallback(() => {
    if (positions) {
      const { desktop, mobile } = positions;
      return (
        <div
          style={{
            position: 'absolute',
            transform: 'translate(-50%,-50%)',
            top: `${isMobile ? mobile?.top : desktop?.top}%`,
            left: `${isMobile ? mobile?.left : desktop?.left}%`,
          }}
        >
          {storeFlag ? (
            <div className={styles.storeButtons}>
              <Image
                alt="google_store"
                src={getGoogleStore}
                onClick={() =>
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.eqifi.eqifi&gl=RO',
                    '_blank',
                  )
                }
              />
              <Image alt="apple_store" src={comingSoonApple} />
            </div>
          ) : (
            <div className={styles.buttonLink} onClick={() => window.open(link?.to, '_blank')}>
              {link?.text}
              <Image alt="arrowRight" src={arrowRight} />
            </div>
          )}
        </div>
      );
    }
  }, [storeFlag, positions, link, isMobile]);

  return (
    <div className={styles.adContainer}>
      {fullWidth && (
        <div className="position--relative">
          <Image alt="ad" src={isMobile ? mobileImage : desktopImage} />
          {renderButtons()}
        </div>
      )}
      {!fullWidth && (
        <div className="container">
          <div className="row">
            <div className="col-12 position-relative">
              <Image
                alt="ad"
                className="border-radius--20px"
                src={isMobile ? mobileImage : desktopImage}
              />
              {renderButtons()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Ad.propTypes = {
  fullWidth: PropTypes.bool,
  desktopImage: PropTypes.string,
  mobileImage: PropTypes.string,
  storeFlag: PropTypes.bool,
  positions: PropTypes.any,
  link: PropTypes.object,
};

export default Ad;
