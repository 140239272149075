import React, { useEffect, useState } from 'react';
import WalletConnect from '@walletconnect/client';
import QRCodeModal from '@walletconnect/qrcode-modal';

import { connectToWalletConnectHandler, subscribeToEvents } from 'helpers/wallet/walletHelpers';
import Image from 'components/Image';

import connectArrow from 'assets/images/connect_arrow.svg';

import styles from './styles.module.css';

const bridge = 'https://bridge.walletconnect.org';

const WalletConnectComponent = ({
  setConnector,
  walletFlag,
  setWalletFlag,
  setNetworkId,
  networkId,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [correctNetwork, setCorrectNetwork] = useState(true);

  const wallet = JSON.parse(localStorage.getItem('walletconnect'));

  const connect = () => {
    // create new connector
    const cc = new WalletConnect({
      bridge,
      qrcodeModal: QRCodeModal,
      qrcodeModalOptions: {
        desktopLinks: [],
      },
    });
    subscribeToEvents(cc, setConnector, setWalletFlag, setNetworkId);
    connectToWalletConnectHandler(cc);
  };

  useEffect(() => {
    if (!!wallet) {
      const newConnector = new WalletConnect({
        uri: `wc:${wallet?.handshakeTopic}@${wallet?.version}?bridge=${wallet?.bridge}&key=${wallet?.key}`,
      });
      setConnector(newConnector);
    }

    return () => setConnector({});
  }, []);

  useEffect(() => {
    setCorrectNetwork(networkId === Number(process.env.REACT_APP_CHAINID));
  }, [networkId]);

  return (
    <>
      {walletFlag ? (
        <>
          <div
            className={`${styles.connectedBox}  ${menuOpen && styles.menuBorder}`}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {correctNetwork ? (
              <div className={styles.connectText}>
                <div className={styles.pulseAnimation} />
                <span>Connected</span>
              </div>
            ) : (
              <div className={styles.wrongBgColor}>
                <span className={styles.wrongColor}>Wrong Network</span>
              </div>
            )}
            <div className={styles.icon}>
              <Image
                alt="icon"
                src={connectArrow}
                className={menuOpen ? styles.expandAnimation : styles.collapseAnimation}
              />
            </div>
            {menuOpen && (
              <div
                className={`${styles.menu} ${!correctNetwork && 'height--100px '}`}
                onClick={connect}
              >
                {!correctNetwork && (
                  <div className={styles.wrongInfo}>
                    Wrong Network. Please switch to the <b>Ethereum Network</b> from your wallet.
                  </div>
                )}
                <div className={styles.disconnect}>Disconnect Wallet</div>
              </div>
            )}
          </div>
        </>
      ) : (
        <button type="button" onClick={connect} className={styles.walletConnect}>
          Connect Wallet
        </button>
      )}
    </>
  );
};

export default WalletConnectComponent;
