import React from 'react';

import NftSale from 'pages/NftSale';
import './App.css';

const App = () => {
  return <NftSale />;
};

export default App;
