import React from 'react';

import Image from 'components/Image';
import useIsMobile from 'helpers/hooks/useIsMobile';

// assets
import footerDesktop from 'assets/images/desktop_footer.png';
import footerMobile from 'assets/images/mobile_footer.png';
import eqifiLogo from 'assets/images/eqifi_logo_footer.png';
import websiteIcon from 'assets/images/website_icon.svg';
import cryptoAgency from 'assets/images/crypto_agency.png';

// social media
import twitterIcon from 'assets/images/twitter_icon.svg';
import linkedinIcon from 'assets/images/linkedin_icon.svg';
import cursorIcon from 'assets/images/cursor_icon.svg';
import youtubeIcon from 'assets/images/youtube_icon.svg';

import styles from './styles.module.css';

const socialLinks = [
  { icon: twitterIcon, to: 'https://twitter.com/eqifi_finance' },
  { icon: linkedinIcon, to: 'https://www.linkedin.com/company/eqifi' },
  { icon: cursorIcon, to: 'https://t.me/eqifi' },
  { icon: youtubeIcon, to: 'https://www.youtube.com/channel/UC3U6AdHPO1Wr71_eZkGDqBQ' },
];

const Footer = () => {
  const isMobile = useIsMobile();

  return (
    <div className={styles.footerContainer}>
      <Image
        alt="footer_bg"
        src={isMobile ? footerMobile : footerDesktop}
        className={styles.footerBg}
      />
      <div className={styles.footerContent}>
        <Image alt="footer_logo" src={eqifiLogo} />
        <button
          type="button"
          onClick={() => window.open('https://www.eqifi.com/', '_blank')}
          className={styles.visitWebsite}
        >
          Visit Website
          <Image alt="visit_website" src={websiteIcon} />
        </button>
        <div className={styles.socialMediaRow}>
          {socialLinks?.map((link, index) => {
            return (
              <Image
                key={index}
                alt={`social-${index}`}
                src={link?.icon}
                onClick={() => window.open(link?.to, '_blank')}
              />
            );
          })}
        </div>
        <div className="container">
          <div className={styles.copyRightTermsRow}>
            <div className={styles.termsPrivacy}>
              <span
                className={styles.hoverEffect}
                onClick={() => window.open('https://www.eqifi.com/legal/', '_blank')}
              >
                Terms & Conditions | Privacy Policy
              </span>
            </div>
            <div className={styles.producedBy}>
              <span>Produced by:</span>
              <Image alt="crypto_beast" src={cryptoAgency} />
            </div>
            <div className={`${styles.producedBy} ${styles.hoverEffect}`}>
              Copyright © 2022 EQIFi. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
