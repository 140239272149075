import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import scrollIntoView from 'scroll-into-view';

import Image from 'components/Image';
import Spinner from 'components/Spinner';
import WalletConnect from 'components/WalletConnect';

import globeIcon from 'assets/images/globe_icon.svg';
import instagramIcon from 'assets/images/instagram_icon.svg';
import arrowDown from 'assets/images/arrow_down.svg';
import arrowRight from 'assets/images/arrow_right.svg';

import { buyNft } from 'helpers/wallet/walletHelpers';

import { numberWithCommas } from 'helpers/common';

import styles from './styles.module.css';

const ArtistWithNfts = ({
  artist,
  nfts,
  index,
  connector,
  balance,
  setConnector,
  walletFlag,
  setWalletFlag,
  setNetworkId,
  networkId,
}) => {
  const [loading, setLoading] = useState(false);
  const [tx, setTx] = useState();

  const scrollIntoList = () => {
    const nftList = document.getElementById(`nftList-${index}`);
    scrollIntoView(nftList, {
      time: 500,
      align: 'top',
    });
  };

  const scrollToChangelly = () => {
    const changelly = document.getElementById('changelly-id');
    scrollIntoView(changelly, {
      time: 500,
      align: 'center',
    });
  };

  const renderPurchaseButton = useCallback(
    (nft) => {
      return (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <button
              type="button"
              disabled={!!(nft?.price?.toFixed(2) > balance)}
              className={styles.purchase}
              onClick={async () => {
                const res = await buyNft(nft, connector, setLoading);
                setTx(res);
              }}
            >
              Purchase
              <Image alt="arrowRight" src={arrowRight} />
            </button>
          )}
        </>
      );
    },
    [connector, balance, setLoading, loading],
  );

  return (
    <div className={styles.section}>
      <div className="container">
        {/* artist box */}
        <div className="row">
          <div className="col-12">
            <div className={styles.artistBox}>
              <Image alt="image" src={artist?.artistImage} className={styles.artistImage} />
              <div className={styles.artistInfo}>
                <div className={styles.title}>{artist?.artistName}</div>
                <div className={styles.description}>{artist?.artistDescription}</div>
                <div className={styles.cta}>
                  <button onClick={scrollIntoList}>
                    <span>See artworks</span>
                    <Image alt="arrow_down" src={arrowDown} />
                  </button>
                  {artist?.artistInstagramLink && (
                    <Image
                      alt="instagram"
                      src={instagramIcon}
                      onClick={() => window.open(artist?.artistInstagramLink, '_blank')}
                    />
                  )}
                  {artist?.artistWebsiteLink && (
                    <Image
                      alt="website"
                      src={globeIcon}
                      onClick={() => window.open(artist?.artistWebsiteLink, '_blank')}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* nft list */}
        <div className={styles.nftList} id={`nftList-${index}`}>
          {nfts?.map((nft, index) => {
            return (
              <div className={styles.nftClass} key={index}>
                <div className={styles.nftBox}>
                  {nft?.nftData?.animation_url ? (
                    <video
                      muted
                      playsInline={true}
                      loop
                      preload="auto"
                      autoPlay
                      src={nft?.nftData?.animation_url}
                    />
                  ) : (
                    <Image alt="nft" src={nft?.nftData?.image} />
                  )}
                  {nft?.sold && (
                    <div className={styles.soldAnnounce}>
                      <span>SOLD</span>
                    </div>
                  )}
                </div>
                <div className={styles.nftInfo}>
                  <div className={styles.nftAbout}>
                    <div className={styles.nftArtist}>
                      {/* description */}
                      <span className={styles.nftDescription}>{nft?.nftData?.name}</span>
                      {/* artist name */}
                      <span className={styles.artistName}>{nft?.nftData?.artistName}</span>
                    </div>
                    <div className={styles.nftPrices}>
                      {/* eqx price row */}
                      <span className={styles.eqxPrice}>
                        {`${numberWithCommas(nft?.price?.toFixed(2))} EQX`}
                      </span>
                      {/* dollar price */}
                      <span className={styles.dollarPrice}>
                        {`$${numberWithCommas(nft?.usdPrice?.toFixed(2))}`}
                      </span>
                    </div>
                  </div>
                  {tx?.id === nft?.id && (
                    <>
                      {!!tx?.status ? (
                        <div className={styles.successMessage}>
                          You have successfully purchased this NFT Artwork!
                        </div>
                      ) : (
                        <div className={styles.successMessage} style={{ color: '#F04259' }}>
                          Artwork was already bought.
                        </div>
                      )}
                    </>
                  )}

                  {nft?.price?.toFixed(2) > balance && !nft?.sold && (
                    <div className={styles.balanceRow}>
                      Not enough EQX to purchase this NFT artwork.
                    </div>
                  )}

                  <div className={styles.buttonsRow}>
                    <span className={styles.buyEqx} onClick={scrollToChangelly}>
                      Buy EQX
                      <Image alt="arrowRight" src={arrowRight} />
                    </span>
                    {!nft?.sold ? (
                      <>
                        {walletFlag ? (
                          renderPurchaseButton(nft)
                        ) : (
                          <WalletConnect
                            setConnector={setConnector}
                            walletFlag={walletFlag}
                            setWalletFlag={setWalletFlag}
                            setNetworkId={setNetworkId}
                            networkId={networkId}
                          />
                        )}
                      </>
                    ) : (
                      <div className={`${styles.purchase} ${styles.sold}`}>SOLD</div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

ArtistWithNfts.propTypes = {
  artist: PropTypes.shape({
    artistDescription: PropTypes.string,
    artistImage: PropTypes.string,
    artistInstragamLink: PropTypes.string,
    artistName: PropTypes.string,
    artistWebsiteLink: PropTypes.string,
  }),
  nfts: PropTypes.arrayOf(PropTypes.any),
};

export default ArtistWithNfts;
